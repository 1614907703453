header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #f5f5f5;
	color: black;
	height: 80px;
}

.title {
	margin: 10px 20px;
	font-size: 24px;
	float: left;
	color: black;
}

ul {
	list-style: none;
	display: flex;
	margin: 0;
	padding: 0;
	width: 300px;
	height: 100%;
	justify-content: center;
	align-items: center;
}

.nav-links {
	float: right;
}

.box {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.box:hover {
	background-color: black;
	color: white;
	transition: ease-in-out 0.3s;
	cursor: pointer;
}

a,
li {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

#wanderMart {
	justify-content: left;
}

.link a {
	text-decoration: none;
	color: inherit;
}

@media screen and (max-width: 500px) {
	ul {
		width: 200px;
		margin: 0px 15px;
	}
}
