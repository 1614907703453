.main {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.card {
	background-color: grey;
	width: 90%;
	max-width: 1200px;
	height: 200px;
	margin: 40px 5% 0px 5%;
	border-radius: 1em 1em 0em 0em;
	display: flex;
	justify-content: left;
	align-items: left;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.card2 {
	background-color: rgb(0, 0, 0);
	width: 90%;
	max-width: 1200px;
	height: 600px;
	margin: 0px 5% 40px 5%;
	display: flex;
	justify-content: center;
	border-radius: 0em 0em 1em 1em;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.ProfileCardPic {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	position: relative;
	top: 130px;
	left: 50px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 1);
	z-index: 1;
}

/* .rating,
.rating .recharts-pie {
    top: 121px;
    left: -77px;
    z-index: 0;
} */

.shopName {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
}

.shopName h1 {
	width: 100%;
	text-align: right;
	padding: 0px 50px;
	margin: 10px;
}

.rating-section {
	margin: 80px 10px 40px 40px;
	width: 30%;
	max-width: 200px;
	min-width: 200px;
	background-color: grey;
	border-radius: 0.5em 0em 0em 0.5em;
	box-shadow: 0 0 20px rgba(0, 0, 0, 1);
}

.order-section {
	margin: 80px 40px 40px 10px;
	width: 60%;
	display: flex;
	background-color: grey;
	border-radius: 0em 0.5em 0.5em 0em;
	box-shadow: 0 0 20px rgba(0, 0, 0, 1);
}

.orderMenu {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.orderMenu h3 {
	margin: 12px 25px;
	text-align: left;
}

.orderList {
	display: flex;
	flex-direction: row;
	width: 90%;
	justify-content: center;
	align-items: center;
	background-color: #242726;
	border-radius: 0.4em;
	margin: 4px;
}

.reviewList {
	flex-direction: row;
	width: 97%;
	height: 50px;
	background-color: #242726;
	border-radius: 0.4em;
	margin: 4px 2px;
}

.review {
	font-size: 15px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.order {
	width: 60%;
	display: flex;
	justify-content: space-around;
	flex-direction: row;
}

.order-buttons {
	width: 40%;
}

#accept {
	background-color: transparent;
	color: white;
	margin: 5px;
	width: 70px;
	padding: 3px;
	border-radius: 0.3em;
	border-color: transparent;
	transition: ease-in-out 0.3s;
	border: 1.9999px solid green;
}

#reject {
	background-color: transparent;
	color: white;
	margin: 5px;
	width: 70px;
	padding: 3px;
	border-radius: 0.3em;
	border-color: transparent;
	transition: ease-in-out 0.3s;
	border: 0.1px solid red;
}

#reject:hover {
	background-color: red;
	color: black;
	border-color: transparent;
}

#accept:hover {
	background-color: green;
	color: black;
	border-color: transparent;
}

#viewButton:hover {
	cursor: pointer;
	background-color: #242726;
	border-radius: 0.2em;
}

.scrollMenu {
	height: 100%;
	width: 100%;
	overflow-y: auto;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.reviewMenu {
	height: 215px;
	width: 100%;
	display: flex;
	/* justify-content: sp; */
	align-items: center;
	flex-direction: column;
}

.reviewDiv {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.reviewSection {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	overflow-y: auto;
}

.rated-review {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.rate {
	display: flex;
	background-color: #242726;
	width: 95%;
	height: 45px;
	border-radius: 0.4em;
	justify-content: center;
	align-items: center;
}

.view-all-button button {
	margin: 10px 5%;
	width: 100%;
	background-color: transparent;
	border-color: transparent;
	color: white;
}

.read {
	background-color: transparent;
	border: 1px solid yellow;
	color: white;
	border-radius: 0.2em;
	transition: ease-in-out 0.3s;
}

.read:hover {
	background-color: yellow;
	color: black;
}

#reviewStar {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5px;
	height: 35px;
}

.modal-overlay {
	background-color: transparent;
	backdrop-filter: blur(5px);
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
}

.modal-content {
	width: 90%;
	max-width: 600px;
	height: 400px;
	margin: auto;
	padding: 20px;
	border-radius: 1em;
	background-color: black;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}
.modal-content-review {
	width: 90%;
	max-width: 600px;
	height: 400px;
	margin: auto;
	padding: 20px;
	border-radius: 1em;
	background-color: black;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	/* align-items: center; */
}

.rating-div {
	display: flex;
	flex-direction: row;
	justify-items: center;
	align-items: center;
}

.rating-div input {
	height: 20px;
	margin: 20px;
}

.review-submit {
	display: flex;
	justify-content: center;
	align-items: center;
}
.review-submit button {
	margin: 20px;
	border-radius: 0.3em;
	border: 2px solid black;

	transition: ease-in-out 0.3s;
}
.review-submit button:hover {
	/* color: white; */
	background-color: yellow;
	border: 2px solid yellow;
	cursor: pointer;
}

.review-heading {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.reviewText {
	background-color: grey;
	width: 100%;
	height: 100%;
	border-radius: 0.3em 0em 0em 0.3em;
	color: white;
	overflow-y: auto;
}

.close {
	margin: 12px;
	width: 55px;
	height: 30px;
	background-color: transparent;
	color: white;
	border-color: transparent;
	transition: ease-in-out 0.3s;
	border-radius: 0.2em;
}

.close:hover {
	cursor: pointer;
	margin: 12px;
	width: 55px;
	height: 30px;
	background-color: grey;
	color: white;
	border-color: transparent;
}

.rating {
	margin: 10px 10px 50px 10px;
}

.star-filled,
.star-half-filled {
	color: yellow;
	font-size: 35px;
}

.star-empty {
	color: black;
	font-size: 35px;
}

@media screen and (min-width: 350px) and (max-width: 500px) {
	.card {
		height: 180px;
	}

	.ProfileCardPic {
		width: 80px;
		height: 80px;
		top: 130px;
		left: 30px;
	}

	.shopName {
		width: 100%;
		height: 100%;
	}

	.shopName h1 {
		width: 100%;
		text-align: right;
		padding: 0px 30px;
		font-size: 25px;
		margin: 10px;
	}

	.rating {
		top: 120px;
		left: -60px;
		z-index: 0;
	}
}

@media screen and (max-width: 350px) {
	.card {
		height: 130px;
	}

	.ProfileCardPic {
		width: 60px;
		height: 60px;
		top: 98px;
		left: 30px;
	}

	.shopName {
		width: 100%;
		height: 100%;
	}

	.shopName h1 {
		width: 100%;
		text-align: right;
		padding: 0px 20px;
		font-size: 20px;
		margin: 10px;
	}

	.rating {
		top: 87px;
		left: -40px;
		z-index: 0;
	}
}

@media screen and (max-width: 600px) {
	.orderMenu {
		/* display: flex; */
		width: 100%;
		height: 500px;
		/* flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        justify-content: center; */
	}

	.rating-section {
		width: 90%;
		margin: 10px;
		position: relative;
		/* top: 15px; */
		max-width: 2100px;
		background-color: grey;
		border-radius: 0.5em;
	}

	.card2 {
		height: 1100px;
		flex-direction: column;
		align-items: center;
	}

	.order-section {
		margin: 10px;
		width: 90%;
		border-radius: 0.5em;
		position: relative;
		top: 30px;
	}
}

::-webkit-scrollbar {
	height: 2px;
}

::-webkit-scrollbar-thumb {
	background-color: #000;
	border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #000;
}

::-webkit-scrollbar-track {
	background-color: grey;
	border-radius: 4px;
}

::-webkit-scrollbar-track:hover {
	background-color: grey;
}
